<template>
  <div class="signIn">
    <!-- <img class="banner-header" src="@/assets/img/signIn/signInBanner.png" alt=""> -->
    <div class="courseInfo-area">
      <div class="course-name">{{offlineCourseData.topic || "暂无"}}</div>
      <div class="course-cont">
        <div class="course-item" v-if="offlineCourseData.type != 1">
          <!-- <img src="@/assets/img/signIn/signIcon1.png" alt=""> -->
          <span>授课课程</span>
          <span class="value">{{offlineCourseData.courseName || "暂无"}}</span>
        </div>
        <div class="course-item">
          <!-- <img src="@/assets/img/signIn/signIcon2.png" alt=""> -->
          <span>授课时间</span>
          <span class="value">{{this.$way.timestampReturnDate(offlineCourseData.startTime, 'timeYMDHM') || "暂无"}}</span>
        </div>
        <div class="course-item">
          <!-- <img src="@/assets/img/signIn/signIcon3.png" alt=""> -->
          <span>授课时长</span>
          <span class="value">{{offlineCourseData.duration || 0}}分钟</span>
        </div>
        <div class="course-item">
          <!-- <img src="@/assets/img/signIn/signIcon4.png" alt=""> -->
          <span>授课地点</span>
          <span class="value">{{offlineCourseData.place || "暂无"}}</span>
        </div>
        <div class="course-item">
          <!-- <img src="@/assets/img/signIn/signIcon5.png" alt=""> -->
          <span>授课老师</span>
          <span class="value">{{offlineCourseData.teacherName || "暂无"}}</span>
        </div>
      </div>
      <div class="sign-area">
        <div :class="['signin-circle']" @click="offlineteachSignIn">
          <span>{{isSign ? '已签到' : '点击签到'}}</span>
          <span class="s-time">{{nowTime}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
export default {
  name: 'signIn',
  data () {
    return {
      nowTime: null,
      type: null,
      timer: null,
      isSign: false, // 是否签到
      signTime: null, // 签到时间
      offlineCourseData: {},
      signData: {}
    }
  },
  watch: {
    '$store.state.isGetToken' (val) {
      if (val && this.$route.name == 'signIn') {
        this.init()
      }
    }
  },
  mounted () {
    if (this.$route.name == 'signIn') {
      console.log(this.$zhxtCore.isHasToken())
      // 本地是否存在token
      if (this.$zhxtCore.isHasToken()) { this.init() } else { this.$zhxtCore.getZhxtToken() }
    }
  },
  beforeDestroy () {
    this.clearEvent()
  },
  methods: {
    // 初始化
    init () {
      this.type = this.$store.state.deviceType
      this.getOfflineteachingDetails()
      this.getTime()
      this.getOfflineCourseStatus()
    },
    // 获取线下授课详情
    getOfflineteachingDetails () {
      this.$request.getOfflineteachingDetails(this.$route.query.offlineTeachingId).then(res => {
        if (res.data.code == 0) {
          this.offlineCourseData = res.data.data
        }
      })
    },
    // 获取线下课程签到状态
    getOfflineCourseStatus () {
      const obj = {
        offlineTeachingId: this.$route.query.offlineTeachingId
      }
      this.$request.offlineCourseStatus(obj).then(res => {
        if (res.data.code == 0) {
          this.signData = res.data.data
          console.log(this.signData)
          if (this.signData.checkInStatus == 1) {
            this.clearEvent()
            this.isSign = true
            this.nowTime = this.$way.timestampReturnDate(this.signData.checkInTime, 'timeHMS')
            console.log(this.$way.timestampReturnDate(this.signData.checkInTime, 'timeHMS'))
          }
        }
      })
    },
    getTime () {
      this.timer = setInterval(() => {
        this.nowTime = new Date().Format('HH:mm:ss')
      }, 1000)
    },
    // 签到
    offlineteachSignIn () {
      // alert(this.nowTime)
      if (this.isSign) return
      this.signTime = this.nowTime
      const obj = {
        offlineTeachingId: this.$route.query.offlineTeachingId
      }
      this.$request.offlineteachSignIn(obj).then(res => {
        if (res.data.code == 0) {
          Toast.success(res.data.msg || '签到成功')
          this.isSign = true
          this.clearEvent()
          this.nowTime = this.signTime
        } else if (res.data.code == 1) {
          this.tipEvent()
        }
      })
    },
    tipEvent () {
      Dialog.alert({
        title: '提示',
        message: '你不在此次课程授课范围内无法签到',
        confirmButtonText: '我已知悉',
        confirmButtonColor: '#1A7EE2'
      }).then(() => {
        // on close
      })
    },
    // 消除定时器
    clearEvent () {
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang="scss" scoped>
  .signIn {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 8px 12px 0;
    background: #EDEEF2;
    .course-name {
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      font-size: 18px;
      color: #333333;
      line-height: 16px;
      margin: 15px 21px;
    }
    .course-cont {
      margin: 0 auto;
      background: #FFFFFF;
      border-radius: 10px;
      padding: 25px 21px;
      display: flex;
      flex-wrap: wrap;
      gap: 23px 0;
      .course-item {
        width: 50%;
        display: flex;
        flex-direction: column;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        font-size: 14px;
        color: #B2B2B2;
        gap: 14px;
        .value {
          font-family: PingFang-SC-Medium;
          font-weight: 500;
          font-size: 15px;
          color: #333333;
        }
      }
    }
    .sign-area {
      .signin-circle {
        width: 175px;
        height: 175px;
        background: linear-gradient(171deg, #5B9FF8, #287AF6);
        // box-shadow: 5px 30px 30px 30px #CADFFF;
        border-radius: 50%;
        margin: 59px auto 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-family: PingFang-SC;
        font-size: 20px;
        color: #B7CFF9;
        line-height: 16px;
        & > span:first-of-type {
          font-weight: bold;
          font-size: 24px;
          color: #FFFFFF;
          margin-bottom: 17px;
        }
      }
    }
  }
</style>
